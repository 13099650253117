import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutComp from "../components/about-comp/AboutComp";

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title={"Cohort"}
        description={"The meeting place for people, products, and great design"}
      />
      <AboutComp />
    </Layout>
  );
};

export default AboutPage;
