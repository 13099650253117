import KoraLogo from "../reusables/company-logos/KoraLogo";
import QuidaxLogo from "../reusables/company-logos/QuidaxLogo";
// import PiggyvestLogo from "../reusables/company-logos/PiggyvestLogo";
import FincraLogo from "../reusables/company-logos/FincraLogo";
import DandeLogo from "../reusables/company-logos/DandeLogo";

export const programs = [
  {
    title: "Structured Learning",
    description:
      "Our program follows a carefully curated curriculum that blends theory with hands-on application.",
  },
  {
    title: "Expert Mentorship",
    description:
      "Learn directly from experienced product designers and industry leaders.",
  },
  {
    title: "Practical Training",
    description:
      "Work on real-world projects and build a portfolio that gets you hired.",
  },
  {
    title: "Career Support",
    description:
      "Gain confidence to apply for roles, ace design interviews, and thrive in a product team.",
  },
];

export const logos = [
  KoraLogo,
  QuidaxLogo,
  // PiggyvestLogo,
  FincraLogo,
  DandeLogo,
];
