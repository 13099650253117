import React from "react";
import { logos, programs } from "./utils-aboutComp";

import "./about-comp.scss";
import NewFooter from "../reusables/new-footer/NewFooter";

function AboutComp() {
  return (
    <section className="slide whiteSlide kenBurns about-comp">
      <div className="about-comp__hero">
        <h1 className="ae-1">About The Unflux</h1>
        <p className="ae-2">
          We believe that design is at the core of how everything works—which is
          why we are committed to helping people think like designers and apply
          structured design principles to solve problems effectively.
        </p>
      </div>

      <div className="about-comp__mission ae-3">
        <div className="about-comp__mission-description">
          <h4>Our Mission</h4>
          <p>
            We're on a mission to empower aspiring and experienced product designers by providing
            high-quality, structured learning, expert mentorship, and hands-on
            training that prepares them for real-world challenges. We focus on
            both the skill of design and the business of design, ensuring that
            our participants not only master UI/UX but also understand how to
            build products that create business value.
          </p>
        </div>
        <div className="about-comp__mission-quote">
          <p>
            <strong>
              <q>
                “The Unflux is focused on educating people about product design,
                with a focus on excellence, solutions-thinking and business
                value.”
              </q>
            </strong>
          </p>
          <div className="quote-author">
            <strong>Elias Awili</strong>
            <small>Chief Facilitator</small>
          </div>
        </div>
      </div>

      <div className="about-comp__why-influx ae-4">
        <h4>Why The Unflux?</h4>
        <p>
          Whether you&apos;re a career switcher, newbie, or design expert, The
          Unflux provides the perfect set of resources for a successful career
          journey in product design.
        </p>
      </div>

      <div className="about-comp__programs ae-5">
        {programs.map(program => (
          <div className="-program" key={program.title}>
            <h6>{program.title}</h6>
            <p>{program.description}</p>
          </div>
        ))}
      </div>

      <div className="about-comp__future ae-6">
        <h4>The Future We See</h4>
        <p>
          The Unflux is not just a training program—it’s a growing ecosystem of
          tech talents. Our long-term goal is to train, mentor, and connect
          designers with high-demand opportunities in the global market.
        </p>
        <p>
          Want to be a part of this vision?{" "}
          <a href="mailto:hello@theunflux.com">Contact Us</a>
        </p>
      </div>

      <div className="about-comp__partners ae-7">
        <h4>Our Coaches, Alumni and Partners work at top companies</h4>

        <ul>
          {Array(2)
            .fill(logos)
            .flat()
            .map((Logo, index) => (
              <li key={index}>
                <Logo />
              </li>
            ))}
        </ul>
      </div>
      <NewFooter />
    </section>
  );
}

export default AboutComp;
